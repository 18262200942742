import { TemplateRef } from '@angular/core';

export interface TableCell<T> {
	dataRaw: T | null;
	data: {
		id: string;
		columnId: string;
		value: TableCellValue;
		cellType?: TableCellType;
		placeholder?: string;

		properties?: Partial<TableCellPropertes>;

		/** Конфигурация ячеек типа Select */
		selectConfig?: TableCellSelectConfig;

		/** Формат для ячеек типа Date (по умолчанию 'dd.MM.yyyy') */
		dateFormat?: string;

		/** Определяет положение контента внутри ячейки (слева, по центру, справа) */
		position?: TableCellPosition;

		/** Флаг определяющий возможность редактирования данных в ячейке */
		canEdit?: boolean;

		/** Флаг определяющий возможность выделения ячейки (подсветить границы синим при нажатии) */
		canSelect?: boolean;

		/** Флаг для назначения особого стиля для неактивных ячеек */
		disabled?: boolean;

		/** Флаг показывающий, что ячейку редактирует другой пользователь
		 * (актуально для таблиц с возможностью одновременного редактирования через WS) */
		disableByRemote?: boolean;

		/** Конфигурация для отображения данных о том кто редактирует ячейку
		 * (актуально для таблиц с возможностью одновременного редактирования через WS) */
		disableByRemoteConfig?: TableCellDisabledByRemoteConfig;
	};
}

export type TableCellValue =
	| TableNumberCell
	| TableStringCell
	| TableDateCell
	| TableSelectCell
	| TableBooleanCell;

export type TableNumberCell = number;
export type TableStringCell = string;
export type TableBooleanCell = boolean;
export type TableDateCell = string | Date;

export type TableSelectCell =
	| {
			id: string;
			value: string;
	  }
	| {
			id: string;
			value: string;
	  }[];

export type TableCellDisabledByRemoteConfig = {
	userId: number;
	fullName: string;
	avatar: string;
};

export type TableCellSelectConfig = {
	options: unknown[];
	multiselect: boolean;
	compareField: string;
	displayField?: string;
	prefixTemplateRef?: TemplateRef<unknown>;
};

export const TableCellType = {
	Boolean: 'boolean',
	Number: 'number',
	String: 'string',
	Date: 'date',
	Files: 'files',
	Select: 'select',
	Formula: 'formula',
	Link: 'link',
} as const;

export type TableCellPropertes = {
	valueBackgroundToken: string;
	valueColorToken: string;
	cellBackgroundToken: string;
};

export type TableCellType = (typeof TableCellType)[keyof typeof TableCellType];

export const TableCellPosition = {
	Left: 'left',
	Center: 'center',
	Right: 'right',
} as const;

export type TableCellPosition =
	(typeof TableCellPosition)[keyof typeof TableCellPosition];
